/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    a: "a",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Quill comes ready to use in several convenient forms."), "\n", React.createElement(_components.h3, null, "CDN"), "\n", React.createElement(_components.p, null, "A globally distributed and available CDN is provided, backed by ", React.createElement(_components.a, {
    href: "https://www.jsdelivr.com/"
  }, "jsDelivr"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-html"
  }, "<!-- Main Quill library -->\n<script src=\"https://cdn.jsdelivr.net/npm/quill@2.0.0-beta.0/dist/quill.js\"></script>\n<script src=\"https://cdn.jsdelivr.net/npm/quill@2.0.0-beta.0/dist/quill.min.js\"></script>\n\n<!-- Theme included stylesheets -->\n<link href=\"https://cdn.jsdelivr.net/npm/quill@2.0.0-beta.0/dist/quill.snow.css\" rel=\"stylesheet\">\n<link href=\"https://cdn.jsdelivr.net/npm/quill@2.0.0-beta.0/dist/quill.bubble.css\" rel=\"stylesheet\">\n\n<!-- Core build with no theme, formatting, non-essential modules -->\n<link href=\"https://cdn.jsdelivr.net/npm/quill@2.0.0-beta.0/dist/quill.core.css\" rel=\"stylesheet\">\n<script src=\"https://cdn.jsdelivr.net/npm/quill@2.0.0-beta.0/dist/quill.core.js\"></script>\n")), "\n", React.createElement(_components.h3, null, "NPM"), "\n", React.createElement(_components.p, null, "Add Quill as an ", React.createElement(_components.a, {
    href: "//www.npmjs.org/"
  }, "NPM"), " dependency and add it your own build workflow, or use the included built options. Compiled stylesheets are also included in ", React.createElement(_components.code, null, "dist/"), " folder."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "npm install quill@2.0.0-beta.0\n")), "\n", React.createElement(_components.h3, null, "Direct Download"), "\n", React.createElement(_components.p, null, "Quill builds are also available for direct download on every ", React.createElement(_components.a, {
    href: "https://github.com/quilljs/quill/releases/tag/v2.0.0-beta.0"
  }, "release"), "."), "\n", React.createElement(_components.h3, null, "Source"), "\n", React.createElement(_components.p, null, "And of course the complete source code is always available on ", React.createElement(_components.a, {
    href: "https://github.com/quilljs/quill"
  }, "GitHub"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "git clone git@github.com:quilljs/quill.git\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
